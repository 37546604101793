<template>
<div class="col-12 collaps pl-0" v-if="dataloaded">
  <template v-if="type=='mockAnswer'" v-for="(item, index) in practiceQuest" >
              <b-card no-body class="mb-2" :key="item.id+index">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle="item.id+index" @click="currentPage=1" variant="light">
                    {{item.title}}
                    <!-- <span class="icon">
                    <i class="fa fa-angle-up"></i>
                  </span> -->
                    </b-button>
                </b-card-header>
                <b-collapse :id="item.id+index" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div v-if="item.data!=null" class="overflow-auto">
                        <b-table
                            :id="item.id+index"
                            :items="item.data"
                            :fields="mockFields"
                            :per-page="perPage"
                            :current-page="currentPage"
                            hover 
                            outlined
                            :head-variant="'light'" 
                          >
                      
                           <template #cell(title)="data">
                            <i>{{ data.item.title }}</i>
                          </template>
                          <template  #cell(questSrcType)="data">
                            <i v-if="data.item.questSrcType=='text' | data.item.questSrcType=='textMarks' ">{{data.item.questSrc}}</i>
                            <i v-if="data.item.questSrcType=='audio' | data.item.questSrcType=='audioMarks'">
                              <audio :id="data.item.question_id" :src="data.item.questSrc"  controls></audio>
                            </i>
                            <i v-if="data.item.questSrcType=='fill'">{{data.item.questSrc}}</i>
                            <i v-if="data.item.questSrcType=='image'">
                              <img class="describeImage" :src="data.item.questSrc" />
                            </i>
                          </template>
                          <!-- <template v-if="type=='practice'" #cell(user_result)="data">
                            <i>{{ data.item.user_result }}</i>
                          </template> -->
                          <template #cell(question_id)="data">
                            <b-button v-if="data.item.questSrcType!='textMarks' & data.item.questSrcType!='audioMarks'" :disabled="btnText !='Marking'" variant="outline-info" @click="questionAnalysis(data.item)">
                              {{ btnText }}
                            </b-button>
                              <i v-if="data.item.questSrcType=='textMarks'| data.item.questSrcType=='audioMarks'">
                              {{ removeMinus(data.item.user_result)}}</i>
                          </template>
                          </b-table>
                          <b-pagination class="align-right"
                            v-model="currentPage"
                            :total-rows="item.data.length"
                            :per-page="perPage"
                            aria-controls="my-table"
                          ></b-pagination>
                      </div>
                      <div v-else>
                        <p>Not Attempted</p>
                      </div>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
      </template>
      <template v-if="type!='mockAnswer'" v-for="(item, index) in practiceQuest" >
              <b-card no-body class="mb-2" :key="item.id+index">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button block v-b-toggle="item.id+index" @click="currentPage=1" variant="light">
                    {{item.title}}
                    <!-- <span class="icon">
                    <i class="fa fa-angle-up"></i>
                  </span> -->
                    </b-button>
                </b-card-header>
                <b-collapse :id="item.id+index" accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div v-if="item.data.length>0" class="overflow-auto">
                          
                          <b-table
                            :id="item.id+index"
                            :items="item.data"
                            :fields="fields"
                            :per-page="perPage"
                            :current-page="currentPage"
                            hover 
                            outlined
                            :head-variant="'light'"
                          >
                          <template #cell(question_id)="data">
                            <b-button v-if="type=='practice'" variant="outline-info" @click="goToQuestion(data.item.question_id,data.item.question_type)">
                              Select
                            </b-button>
                            <b-button v-else variant="outline-info" @click="goToQuestion(data.item.question_id,item.id)">
                              Select
                            </b-button>
                          </template>
                           <template #cell(title)="data">
                            <i>{{ data.item.title }}</i>
                          </template>
                          <template v-if="type=='practice'" #cell(inserted_date)="data">
                            <i>{{changeDate(data.item.inserted_date, "fullDate")}}</i>
                          </template>
                          <template v-if="type=='predict' | type=='all'" #cell(predicted_date)="data">
                            <i>{{changeDate(data.item.predicted_date, "fullDate")}}</i>
                          </template>
                          <template v-if="type=='exam' | type=='all'" #cell(exam_date)="data">
                            <i>{{changeDate(data.item.exam_date, "fullDate")}}</i>
                          </template>
                          <template v-if="type=='practice'" #cell(user_result)="data">
                            <i>{{ data.item.user_result }}</i>
                          </template>
                          </b-table>
                          <b-pagination class="align-right"
                            v-model="currentPage"
                            :total-rows="item.data.length"
                            :per-page="perPage"
                            aria-controls="my-table"
                          ></b-pagination>
                        </div>
                        <p v-else class="text-center"><strong>No record found for this Section</strong></p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
      </template>
      <b-modal id="result-Modal" size="lg" centered hide-footer :title="modalTitle">
         <b-container v-if="resultModal=='speaking'" class="resultModel" >
               <app-ResultPresent  :apiResults="apiResults"/>
         </b-container>
         <b-container v-if="resultModal=='writing'" class="resultModel" >
          <app-WritingResultPresent :apiResults="apiResults"/>
         </b-container>
         <b-button class="mt-3" variant="outline-dark" left @click="$bvModal.hide('result-Modal')">Close Me</b-button>
       </b-modal>
</div>
<div v-else>
        <p class="text-center">
            <!-- <strong>Loading.....</strong> -->
            <img class="loadingImg" src="@/assets/images/loading.gif"/>
        </p>
</div>
</template>
<script>
import { mapActions,mapGetters } from 'vuex'
import moment from "moment";
import ResultPresent from '../reuseable/resultPresent'
import WritingResultPresent from '../reuseable/writeResultPresent'
import {bus} from '../../main';
import $ from "jquery";
export default {
    props:["type","mockId","userMockId"],
    components:{
      'app-ResultPresent':ResultPresent,
      'app-WritingResultPresent':WritingResultPresent,
    },
  data() {
    return {
      expanded: false,
      currentPage:1,
      perPage:8,
      dataloaded:false,
      modalTitle:"",
      apiResults:[],
      resultModal:"speaking",
      btnText:"Marking",
      fields: [
        {
            key: 'question_id',
            label:'',
            sortable: false
          },
          {
            key: 'title',
            label:'Question Title',
            sortable: true
          }
        ],
        mockFields: [
          {
            key: 'title',
            label:'Title',
            sortable: true
          },
          {
            key: 'questSrcType',
            label:'Description',
            sortable: true
          },
          {
            key: 'question_id',
            label:'Result',
            sortable: false
          }
        ],
      practiceQuest:[
        {
          id:'readAloud',
          table:'tblReadAloud',
          title:'Read Aloud',
          count:0,
          data:[]
        },
        {
          id:'repeatsentence',
          table:'tblRepeatSentence',
          title:'Repeat Sentence',
          count:0,
          data:[]
        },
        {
          id:'describeimage',
          table:'tblDescribeGraph',
          title:'Describe Image',
          count:0,
          data:[]
        },
        {
          id:'retellLecture',
          table:'tblReTellLecture',
          title:'Re-Tell Lecture',
          count:0,
          data:[]
        },
        {
          id:'answerShortQuestion',
          table:'tblShortQuestion',
          title:'Answer Short Question',
          count:0,
          data:[]
        },
        {
          id:'summarized',
          table:'tblSummerizeWrittenText',
          title:'Summarized Written Text',
          count:0,
          data:[]
        },
        {
          id:'essay',
          table:'tblEssayQuestion',
          title:'Write Essay',
          count:0,
          data:[]
        },
        {
          id:'mcqSingle',
          table:'tblMultipleChioce',
          title:'Multiple Choice(Single)',
          count:0,
          data:[]
        },
        {
          id:'mcqMultiple',
          table:'tblMultipleChioceMultiple',
          title:'Multiple Choice(Multiple)',
          count:0,
          data:[]
        },
        {
          id:'reOrderPara',
          table:'tblReOrderParagraph',
          title:'Re-order Paragraphs',
          count:0,
          data:[]
        },
        {
          id:'readFill',
          table:'tblFillinBlankQuestion',
          title:'Reading: Fill in Blanks',
          count:0,
          data:[]
        },
        {
          id:'readWriteFill',
          table:'tblrdfillinblanksq',
          title:'Reading & Writing: Fill in Blanks',
          count:0,
          data:[]
        },
        {
          id:'summarizedSpoken',
          table:'tblsummarizespokenquestion',
          title:'Summarize Spoken Text',
          count:0,
          data:[]
        },
        {
          id:'listMcqMultiple',
          table:'tbllisteningmultichoice',
          title:'Multiple Choice(Multiple)',
          count:0,
          data:[]
        },
        {
          id:'listMcqSingle',
          table:'tblListeningSingleChoice',
          title:'Multiple Choice(Single)',
          count:0,
          data:[]
        },
        {
          id:'highLightSummary',
          table:'tblListeningSummary',
          title:'Highlight Correct Summary',
          count:0,
          data:[]
        },
        {
          id:'selectMissingWords',
          table:'tblListeningMissingWord',
          title:'Select Missing Word',
          count:0,
          data:[]
        },
        {
          id:'listFillBlanks',
          table:'tblListeningFillinBlankQ',
          title:'Fill in Blanks',
          count:0,
          data:[]
        },
        {
          id:'highLightWords',
          table:'tblListeningHighlightInCorrectWords',
          title:'Highlight Incorrect Words',
          count:0,
          data:[]
        },
        {
          id:'writeFromDict',
          table:'tblDectationQuestion',
          title:'Write From Dictation',
          count:0,
          data:[]
        }
      ],
      tabModule:''
    }
  },
  methods: {
    ...mapActions({
            fetchQuestionPractice:'auth/fetchGraphData',
            getTranscripty:'speakmodule/getTranscripty',
            saveAnswer: 'writtingmodule/saveAnswer',
        }),
        ...mapGetters({
            GetQuestionPractice:'auth/getPracticeNumber',
            answerResponse: 'speakmodule/AnswerResponse',
            writingAnswerResponse: 'writtingmodule/AnswerResponse',
        }),
        removeMinus(value){
          //value=value.replace('-','');
          var array=value.split('/');
         // debugger 
          if(array.length>1){
            const temp=array[0].replace(/[^0-9-]+/g,"");
            if(temp>array[1]) value="Your Score: "+array[1]+'/'+array[1];
            else if(temp<0) value="Your Score: "+0+'/'+array[1];
          }
          return  value;
        },
        changeDate(date, dateType) {
          const date1 = new Date(date);
          if (dateType === "fullDate") {
            return moment(date1).format("ddd, MMMM Do YYYY");
          } else {
            return moment(date1).format("HH:mm");
          }
        },
        goToQuestion(inputId,type){
          //console.log(inputId+'-'+type)
          const data={
            id:inputId,
            question:type
          }
          bus.$emit('changeQuestionFromComponents', data);
        },
      toggleCardState() {
      this.expanded = !this.expanded;
    },
    questionAnalysis(selectedItem){
      console.log(selectedItem);
      this.btnText="Fecthing...";
      this.resultModal="speaking";
      if(selectedItem.questType=='readAloud') this.readAloudMarking(selectedItem);
      else if(selectedItem.questType=='describeImage') this.describeImageMarking(selectedItem);
      else if(selectedItem.questType=='repeatSentence') this.repeatSentenceMarking(selectedItem);
      else if(selectedItem.questType=='retelLecture') this.retTellLectureMarking(selectedItem);
      else if(selectedItem.questType=='answerShort') this.shortAnswerMarking(selectedItem);
      else if(selectedItem.questType=='SWT') {
        this.resultModal="writing";
        this.swtMarking(selectedItem);
      }
      else if(selectedItem.questType=='essay') {
        this.resultModal="writing";
        this.essayMarking(selectedItem);
      }
      else if(selectedItem.questType=='SST') {
        this.resultModal="writing";
        this.sstMarking(selectedItem);
      }
    },
    readAloudMarking(item){
      var param={
                user_audio_file: item.user_recording.replace('audio/',''),
                text: item.readaloud_text,
                user_id: item.user_id,
               // quest:'u'+item.user_id+'/q'+item.readaloud_id,
                url:'speechace/transcriptNew'
            }
            this.getTranscripty(param).then(()=>{
              if(this.answerResponse().data.success)
                {
                  if(this.answerResponse().data.result.status!='success'){
                    this.btnText="Marking";
                    return this.$alert('No recording found, Against this question.')
                        
                    }
                    const res=this.answerResponse().data.result;
                    const newFilePath='audio/'+this.answerResponse().data.newFilePath;
                    const textArray=item.readaloud_text.split(' ')
                    const correctWords=res.text_score.fluency.overall_metrics.correct_word_count
                    const totalWords=res.text_score.fluency.overall_metrics.word_count
                    var content=0;
                    if(correctWords==totalWords) content=90
                    else if(correctWords>=totalWords*.85) content=79
                    else if(correctWords>=totalWords*.65) content=73
                    else if(correctWords>=totalWords*.55) content=65
                    else if(correctWords>=totalWords*.45) content=50
                    else if(correctWords>=totalWords*.35) content=35
                    else if(correctWords>=totalWords*.25) content=25
                    else if(correctWords>=totalWords*.15) content=15
                    else if(correctWords>=totalWords*.10) content=10
                    else content=0
                    this.apiResults={
                                        transcript:'',// need to generate
                                        quality_Score:res.text_score.pte_score.overall,// need to calculate
                                        total_Words:totalWords,
                                        correct_Words:correctWords,
                                        vocab:res.text_score.pte_score.vocab,
                                        pte_Estimate:res.text_score.pte_score.fluency,
                                        word_Score_List:res.text_score.word_score_list,
                                        content_Score:content,
                                        textArray:textArray,
                                        text:item.readaloud_text,
                                        src:newFilePath, //item.user_recording.replace('audio/',''),
                                        type:'all'
                                    }
                                    this.modalTitle="ReadAloud AI Result";
                                    this.$bvModal.show("result-Modal");
                                    this.btnText="Marking";

                }
            });
    },
    describeImageMarking(item){
      console.log(item)
      var param={
                  user_audio_file: item.recording.replace('audio/',''),
                  text: item.transcript,
                  user_id: item.user_id,
                  module:"describeimage",
                  //quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.graph_id,
                  url:'speechace/transcriptDeepgramNew'
              };
              // if(item.transcript==null | item.transcript.length < 10){
              //   this.btnText="Marking";
              //   return this.$alert('No recording found, Against this question.')
              // }
              this.getTranscripty(param).then(()=>{
                if(this.answerResponse().data.success)
                                {
                                    if(this.answerResponse().data.result.status!='success'){
                                      this.btnText="Marking";
                                      return this.$alert('No recording found, Against this question.')
                                        
                                    }
                                    const res=this.answerResponse().data.result;
                                    const newFilePath='audio/'+this.answerResponse().data.newFilePath;
                                    var content = 0
                                    var conculsionWords=['finally', 'in a word', 'in brief', 'briefly', 'in conclusion','conclusion', 'in the end', 'in the final analysis', 'on the whole'
            , 'thus', 'to conclude', 'to summarize', 'in sum', 'to sum up', 'in summary','As Can Be Seen','After All','By And Large'
            ,'Generally Speaking','In Fact','To Sum Up','In The Final Analysis','On The Whole','All Things Considered'
            ,'In Any Event','As Shown Above','In Short','In Either Case','In The Long Run','In Brief','All In All'
            ,'Given These Points','In Essence','As Has Been Noted','In A Word','On Balance','For The Most Part'
            ,'Altogether', 'Obviously', 'Overall', 'Ultimately', 'Ordinarily', 'Definitely', 'and Usually',
            'as can be seen','generally speaking','all things considered','as shown above','in the long run','given these points'
            ,'as has been noted','for the most part','As discussed above','picture','can','see','informative'];
                                    const duration=Math.round(res.text_score.fluency.overall_metrics.duration- res.text_score.fluency.overall_metrics.all_pause_duration)
                                    // if(duration>=20 & duration<=38) content=80
                                    // else if(duration<20) content=60
                                    // else if(duration>38) content=60
                                    // else content = 10

                                    let colorsArray=["red","blue","black","orange","green","purple","yellow","pink","white"];

                                    var checkConclusion=0
                                    var correctWords=0
                                    //console.log("KeyWords");
                                    //console.log(this.keywordList)
                                    var array=item.transcript.split(' ')
                                    for (let index = 0; index < array.length; index++) {
                                        const element = array[index].replace(',','').replace('.','').toLowerCase();
                                      
                                        // if(this.keywordList.length>0){
                                        //     for (let item of this.keywordList) {
                                        //        if(item.keyword.toLowerCase()==element) correctWords++;
                                        //     }
                                        //     // let found= this.keywordList.filter((item)=>item.keyword.indexOf(element));
                                        //     // if(found>0) correctWords++;
                                        // }
                                        // else{
                                            var transArray=item.questTransacript.split(' ');
                                            transArray.forEach(transElement => {
                                                if(transElement.replace(',','').replace('.','').toLowerCase()==element) correctWords++;
                                           });
                                        //}
                                         
                                       if(colorsArray.indexOf(element)>-1) correctWords++;
                                       if(conculsionWords.indexOf(element)>-1) checkConclusion++
                                    }

                                    if(correctWords>=10) content=90
                                    else if(correctWords>=8)content=79
                                    else if(correctWords>=5)content=65
                                    else if(correctWords>=3)content=50
                                    else content =10
//console.log(correctWords)
                                    if(content>10){
                                        if ( duration >= 32) content +=90
                                        else if (duration >= 25 ) content +=79
                                        else if ( duration >=20 ) content += 65
                                        else if( duration >= 15) content += 50
                                        else if ( duration >= 10 ) content += 30
                                        else content +=0

                                        //content +=((this.microsoftTranscript.split(this.selectedquestion.title)-1)>=2)? 5: 2;
                                        content +=(checkConclusion>0)?90:45;
                                        content=(correctWords>3)?content/3:content/2;
                                    }

                                    

                                   // content=(correctWords<3)? 10:content;

                                    if(content>90) content=90
                                    content=Math.round(content);
                                    
                                    this.apiResults={
                                        transcript:'',
                                        quality_Score:(content==10)?10:res.text_score.pte_score.pronunciation,
                                        total_Words:res.text_score.fluency.overall_metrics.word_count,
                                        correct_Words:res.text_score.fluency.overall_metrics.correct_word_count,
                                        vocab:res.text_score.pte_score.vocab,
                                        pte_Estimate:(content==10)?10:res.text_score.pte_score.fluency,
                                        word_Score_List:res.text_score.word_score_list,
                                        content_Score:content,
                                        textArray:'',
                                        text:'',
                                        src:newFilePath,
                                        type:'all'
                                    }
                                    this.modalTitle="Describe Image AI Result";
                                    this.$bvModal.show("result-Modal");
                                    this.btnText="Marking";
                                }
              });
    },
    repeatSentenceMarking(item){
      var param={
                  user_audio_file: item.recording.replace('audio/',''),
                  text: item.questTransacript,
                  user_id: item.user_id,
                  //quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.graph_id,
                  url:'speechace/transcriptNew'
              };
              this.getTranscripty(param).then(()=>{
                if(this.answerResponse().data.success)
                  {
                      if(this.answerResponse().data.result.status!='success'){
                        this.btnText="Marking";
                return this.$alert('No recording found, Against this question.')
                      }
                      debugger;
                      const res=this.answerResponse().data.result;
                      const newFilePath='audio/'+this.answerResponse().data.newFilePath;
                      const textArray=item.questTransacript.split(' ');
                      const correctWords=res.text_score.fluency.overall_metrics.correct_word_count
                      const totalWords=res.text_score.fluency.overall_metrics.word_count
                      var content=0;
                      //as the content must be from 90 
                      //content = Math.round((correctWords/totalWords)*100)-10 // as pr instruction they want lienency in marking
                      if(correctWords>=totalWords*.85) content=90
                      else if(correctWords>=totalWords*.65) content=78
                      else if(correctWords>=totalWords*.55) content=67
                      else if(correctWords>=totalWords*.45) content=50
                      else if(correctWords>=totalWords*.35) content=35
                      else if(correctWords>=totalWords*.25) content=25
                      else if(correctWords>=totalWords*.15) content=15
                      else if(correctWords>=totalWords*.5) content=5
                      else content=0
                      this.apiResults={
                                        transcript:'',
                                        quality_Score:res.text_score.pte_score.overall,
                                        total_Words:res.text_score.fluency.overall_metrics.word_count,
                                        correct_Words:res.text_score.fluency.overall_metrics.correct_word_count,
                                        vocab:res.text_score.pte_score.vocab,
                                        pte_Estimate:res.text_score.pte_score.fluency,
                                        word_Score_List:res.text_score.word_score_list,
                                        content_Score:content,
                                        textArray:textArray,
                                        text:item.questTransacript,
                                        src:newFilePath,
                                        type:'all'
                                    }
                                    console.log(this.apiResults);
                                    this.modalTitle="Repeat Sentence AI Result";
                                    this.$bvModal.show("result-Modal");
                                    this.btnText="Marking";
                  }
              });
    },
    retTellLectureMarking(item){
      console.log(item)
      var param={
                  user_audio_file: item.recording.replace('audio/',''),
                  text: item.transcript,
                  user_id: item.user_id,
                  module:"retellecture",
                  //quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.graph_id,
                  url:'speechace/transcriptDeepgramNew'
              };
              // if(item.transcript==null | item.transcript.length < 10){
              //   this.btnText="Marking";
              //   return this.$alert('No recording found, Against this question.')
              // }
              this.getTranscripty(param).then(()=>{
                if(this.answerResponse().data.success)
                  {
                    console.log(this.answerResponse())
                      if(this.answerResponse().data.result.status!='success'){
                        this.btnText="Marking";
                        return this.$alert('No recording found, Against this question.')
                      }
                      const res=this.answerResponse().data.result;
                      const newFilePath='audio/'+this.answerResponse().data.newFilePath;
                      const textArray=item.questTransacript.split(' ')
                      var content=0;
                                    var array=item.transcript.split(' ')
                                    var conculsionWords=['finally', 'in a word', 'in brief', 'briefly', 'in conclusion','conclusion', 'in the end', 'in the final analysis', 'on the whole'
                                      , 'thus', 'to conclude', 'to summarize', 'in sum', 'to sum up', 'in summary','As Can Be Seen','After All','By And Large'
                                      ,'Generally Speaking','In Fact','To Sum Up','In The Final Analysis','On The Whole','All Things Considered'
                                      ,'In Any Event','As Shown Above','In Short','In Either Case','In The Long Run','In Brief','All In All'
                                      ,'Given These Points','In Essence','As Has Been Noted','In A Word','On Balance','For The Most Part'
                                      ,'Altogether', 'Obviously', 'Overall', 'Ultimately', 'Ordinarily', 'Definitely', 'and Usually',
                                      'as can be seen','generally speaking','all things considered','as shown above','in the long run','given these points'
                                      ,'as has been noted','for the most part','As discussed above','topic','lecture','keyword','one','two','descriptive','speaker','hear'];

                                    const correctWords=res.text_score.fluency.overall_metrics.correct_word_count
                                    const totalWords=res.text_score.fluency.overall_metrics.word_count
                                    
                                    if(correctWords>=18) content=90
                                    else if(correctWords>=13) content=70+correctWords
                                    else if(correctWords>=11)content=60+correctWords
                                    else if(correctWords>=10) content=50+correctWords
                                    else if(correctWords>=8)content=35
                                    else if(correctWords>=5)content=25
                                    else content=0

                                    this.apiResults={
                                        transcript:'',
                                        quality_Score:res.text_score.pte_score.overall,
                                        total_Words:res.text_score.fluency.overall_metrics.word_count,
                                        correct_Words:res.text_score.fluency.overall_metrics.correct_word_count,
                                        vocab:res.text_score.pte_score.vocab,
                                        pte_Estimate:res.text_score.pte_score.fluency,
                                        word_Score_List:res.text_score.word_score_list,
                                        content_Score:content,
                                        textArray:textArray,
                                        text:item.transcript,
                                        src:newFilePath,
                                        type:'all'
                                    }
                                    this.modalTitle="Re-Tell Lecture AI Result";
                                    this.$bvModal.show("result-Modal");
                                    this.btnText="Marking";
                    }

                  }
                );
    },
    shortAnswerMarking(item){
      var param={
                  user_audio_file: item.user_recording.replace('audio/',''),
                  text: item.transcript, 
                  user_id: item.user_id,
                  getTrans:true,
                  //quest:'u'+this.userAnswer.user_id+'/q'+this.selectedquestion.graph_id,
                  url:'speechace/transcriptDeepgramNew'
              };
              this.getTranscripty(param).then(()=>{
                if(this.answerResponse().data.success)
                  {
                      if(this.answerResponse().data.result.status!='success'){
                        this.btnText="Marking";
                        return this.$alert('Empty recording found, Against this question.')
                      }
                      const res=this.answerResponse().data.result;
                      console.log(this.answerResponse().data)
                      const newFilePath='audio/'+this.answerResponse().data.newFilePath;
                      //const textArray=item.questTransacript.split(' ')
                      //var content=0;
                      //var array=item.transcript.split(' ')
                      var answer=(this.answerResponse().data.userTrans!=null)?this.answerResponse().data.userTrans.replace('/n',''):"";
                      console.log(answer)
                      var marksGot = 0
                      $.each(item.questTransacript.split(' '),function(index,itema){
                        const word=itema.toLowerCase().replace('.','').trim();
                        answer.split(" ").forEach(actitem=>{
                           
                           if(actitem.toLowerCase().trim()==word & word.trim()!=""){
                            marksGot=1
                               console.log("item >>> ", item);
                               console.log("wod >>>  ",word);
                         }
                         })
                          // if(answer.length>0)
                          // {
                          //       if(answer.toLowerCase().includes(itema.toLowerCase().replace('.',''))) marksGot=1
                          // }
                          // else{
                          //     if(answer.toLowerCase().includes(itema.toLowerCase().replace('.',''))) marksGot=1
                          // }
                      })
                      this.apiResults={
                          transcript:item.questTransacript,//item.transcript,
                          marks:marksGot,
                          text:item.questTransacript,
                          type:'AnswerShort',
                          src:newFilePath
                          }
                          this.modalTitle="Answer Short Question AI Result";
                                    this.$bvModal.show("result-Modal");
                                    this.btnText="Marking";
                    }
                    // const updateAns={
                    //   user_recording_id:item.user_recording_id,
                    //   user_result:marksGot,
                    //   url:"mock",
                    //   tableName:'tblusershortanswer'
                    // }
                    // this.saveAnswer(updateAns).then(()=>{
                    //                         //console.log(this.answerResponse().data)
                    //                         //vm.userAnswerResponse = vm.answerResponse().data
                    //                     })
                  });
    },
    swtMarking(item){
      let lang="en_UK";
      if(item.user_answer.length<10){
        this.btnText="Marking";
        return this.$alert('No answer found, Against this question.')
      }
      var AIWritingData={
          analysisType:"Academic",
          langType:lang,
          questType:"swt",
          userAnswer:item.user_answer,
          question:item.questSrc,
          url:"AIWritting/AIMarkingResult",
          question_Id:item.question_id
      }
      this.saveAnswer(AIWritingData).then(()=>{
          let AIResponse= this.writingAnswerResponse();
          this.apiResults=AIResponse.apiResults;
          this.modalTitle="Summarized Written Text AI Result";
          this.$bvModal.show("result-Modal");
          this.btnText="Marking";
      });
     
    },
    essayMarking(item){
      let lang="en_UK";
      if(item.essay_desc.length<10){
        this.btnText="Marking";
        return this.$alert('No answer found, Against this question.')
      }
      var AIWritingData={
          analysisType:"Academic",
          langType:lang,
          questType:"essay",
          userAnswer:item.essay_desc,
          question:item.questSrc,
          url:"AIWritting/AIMarkingResult",
          question_Id:item.question_id
      }
      this.saveAnswer(AIWritingData).then(()=>{
          let AIResponse= this.writingAnswerResponse();
          this.apiResults=AIResponse.apiResults;
          this.modalTitle="Essay AI Result";
          this.$bvModal.show("result-Modal");
          this.btnText="Marking";
      });
     
    },
    sstMarking(item){
      let lang="en_UK";
      if(item.answer_desc.length<10){
        this.btnText="Marking";
        return this.$alert('No answer found, Against this question.')
      }
      var AIWritingData={
          analysisType:"Academic",
          langType:lang,
          questType:"sst",
          userAnswer:item.answer_desc,
          question:item.transcript,
          url:"AIWritting/AIMarkingResult",
          question_Id:item.question_id
      }
      this.saveAnswer(AIWritingData).then(()=>{
          let AIResponse= this.writingAnswerResponse();
          this.apiResults=AIResponse.apiResults;
          this.modalTitle="Summarized Spoken Text AI Result";
          this.$bvModal.show("result-Modal");
          this.btnText="Marking";
      });
     
    },
    loadPracticeRecord(){
      const currentUser=this.$ls.get('user')
      var dateField={
            key: 'inserted_date',
            sortable: true,
            label:'Practice Date'
          }
      var resultField={
            key: 'user_result',
            label: 'Results',
            sortable: false,
            variant: 'primary'
          }
      this.fields.push(dateField)
      this.fields.push(resultField)
      const url='overallPractice/getPracticeQuestList/id='+currentUser.user_id
          this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                //console.log(response)
                const practiceData=response.data
                this.practiceQuest.forEach(element => {
                  ////console.log(element.id)
                    element.data = practiceData.filter((item)=>{
                      return item.question_type==element.id
                    })
                    
                    ////console.log(element.data)
                });
                //console.log(this.practiceQuest)
                this.dataloaded=true;
            })

    },
    loadPredictRecord(){
      var url='overallPractice/getPredictExamQuestList';
      var examdates={
            key: 'exam_date',
            sortable: true,
            label:'Exam Date',
            variant: 'success'
          }
      var predictDate= {
          key: 'predicted_date',
          sortable: true,
          label:'Predicted Date',
          variant: 'info'
        }
      if(this.type=="exam"){
        this.fields.push(examdates)
         url="overallPractice/getExamQuest"
      } 
      else if(this.type=="predict") 
      {
        this.fields.push(predictDate)
        url="overallPractice/getPredictQuest"
      }
      else{
        this.fields.push(examdates)
        this.fields.push(predictDate)
      }

          this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                //console.log(response)
                const practiceData=response.data
                this.practiceQuest.forEach(element => {
                  ////console.log(element.id)
                    element.data = practiceData.filter((item)=>{
                      return item.table_name==element.table
                    })
                    ////console.log(element.data)
                });
                //console.log(this.practiceQuest)
                this.dataloaded=true;
            })

    },
    loadMockRecord(){
      var url='mocktestresult/mockTestId='+this.userMockId;
      this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                //console.log(response)
                const practiceData=response.answerList
                this.practiceQuest.forEach(element => {
                  console.log(element)
                    // element.data = practiceData.filter((item)=>{
                    //   return item==element.table
                    // })
                    element.data=practiceData[element.table]
                    ////console.log(element.data)
                });
                
                console.log(this.practiceQuest)
                this.dataloaded=true;
           
            })

    },
    loadModuleMockRecord(){
      
      var url=`mocktestresult/moduleMockTestId=${this.userMockId}--${this.tabModule}`;
      this.fetchQuestionPractice(url).then(()=>{
                const response=this.GetQuestionPractice()
                //console.log(response) 
                const practiceData=response.answerList;

                this.practiceQuest.forEach(element => {
                  console.log(element)
                    // element.data = practiceData.filter((item)=>{
                    //   return item==element.table
                    // })
                    element.data=practiceData[element.table]
                    ////console.log(element.data)
                });
                
                console.log(this.practiceQuest)
                this.dataloaded=true;
           
            })

    },
  },
  beforeMount(){
    this.dataloaded=false;
    let tabMod=this.$ls.get('tabModule');
    this.tabModule=(tabMod==null)?this.tabModule:tabMod;
    if(this.type=="practice"){
      this.loadPracticeRecord()
    }
    else if(this.type=="exam" | this.type=="predict" | this.type == "all"){
      this.loadPredictRecord()
    }
    else if(this.type="mockAnswer"){
      console.log(this.mockId);
      console.log(this.userMockId);
      console.log(this.tabModule);
      if(this.tabModule=='Speaking'){
        this.practiceQuest=this.practiceQuest.slice(0,5);
      } 
      else if(this.tabModule=='Writing'){
        this.practiceQuest=this.practiceQuest.slice(5,7);
      } 
      else if(this.tabModule=='Reading'){
        this.practiceQuest=this.practiceQuest.slice(7,12);
      } 
      else if(this.tabModule=='Listening'){
        this.practiceQuest=this.practiceQuest.slice(12,19);
      } 
     (this.tabModule=='')? this.loadMockRecord():this.loadModuleMockRecord();
     
    }
  }
}
</script>
<style>
.table th, .table td {
    vertical-align: middle;
    min-width: 150px;
    border-right: 1px solid #e9ecef;
}
.table td:last-child{
  text-align: center;
  font-weight: 600;
}
</style>
<style scoped>

img{
  height: 200px;
    margin: auto;
}
audio {
    width: 50%;
    height: 50px !important;
    background-color: #f1f3f4;
    border-radius: 5px;
    margin: auto;
    width: 90%;
    border-radius: 10px;
}
.collaps .card-header{
      padding: 0px!important;
          height: 3rem!important;
    /* padding-left: 15px!important; */
}
.collaps .card-header .btn{
overflow-anchor: none;
    text-align: left;
    font-size: 15px!important;
    font-weight: 600;
    border-radius: 0px;
    height: 100%;
    border: 0px!important;
    padding-left: 20px!important;
}

 .collaps .card  .card-header {
    cursor: pointer;
  }
 .collaps .btn .icon {
      transform: rotate(180deg);
      transition: transform 150ms ease-out;
    }
  
      
 .collaps .card  .card-content {
    transition: all 150ms ease;
  }

      
.collaps .card.not-expanded .btn .icon {
      transform: rotate(0deg);
    }
  
 .collaps .card.not-expanded .card-content {
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
    height: 0;
    padding: 0;
  }
.pagination{
  float: right;
}

</style>